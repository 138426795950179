"use client";
import Hero from "@/components/Hero";
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import Pricing from "@/components/Pricing";
import FAQ from "@/components/FAQ";
import ButtonLead from "@/components/ButtonLead";
import FutureFeaturesSection from "@/components/FutureFeatureSession";
import PlausibleProvider from "next-plausible";
import Script from "next/script";
import DocumentSampleList from "@/components/dashboard/DocumentSampleList";
import { useEffect } from "react";

export default function Home() {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (sessionStorage.getItem('referer') === null) {
        const referer = (document.referrer ?? 'direct').substring(0, 158);
        sessionStorage.setItem('referer', referer);
      }
    }
  }, []);

  return (
    <PlausibleProvider domain="supadoc.ai">
      <>
        <Header />
        <main>
          <Hero />
          <ButtonLead extraStyle="mx-auto" />
          <DocumentSampleList />
          <Pricing />
          <FutureFeaturesSection />
          <FAQ />
        </main>
        <Footer />
        <Script src="https://beamanalytics.b-cdn.net/beam.min.js" data-token="21070fa8-f290-47f6-94ac-b7c2dd540214" async></Script>
        <Script id="microsoft-clarity">{`
        (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "k6774mtfom");
        `}</Script>
      </>
    </PlausibleProvider>
  );
}
