/* eslint-disable @next/next/no-img-element */
"use client";

import { useState, useEffect } from "react";
import { User, createClientComponentClient } from "@supabase/auth-helpers-nextjs";
import { useRouter } from "next/navigation";
import config from "@/config";
import ButtonAccount from "./ButtonAccount";

// A simple button to sign in with our providers (Google & Magic Links).
// It automatically redirects user to callbackUrl (config.auth.callbackUrl) after login, which is normally a private page for users to manage their accounts.
// If the user is already logged in, it will show their profile picture & redirect them to callbackUrl immediately.
const ButtonSignin = ({
  text = "Start for Free",
  extraStyle,
}: {
  text?: string;
  extraStyle?: string;
}) => {
  const router = useRouter();
  const supabase = createClientComponentClient();
  const [user, setUser] = useState<User>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    const getUser = async () => {
      const { data } = await supabase.auth.getUser();

      setUser(data.user);
      setIsLoading(false);
    };

    getUser();
  }, [supabase]);

  const handleClick = () => {
    if (user) {
      router.push(config.auth.callbackUrl);
    } else {
      router.push(config.auth.loginUrl);
    }
  };

  return user ? (
    <ButtonAccount />
  ) : (<button
    className={`btn ${extraStyle ? extraStyle : ""}`}
    onClick={isLoading ? undefined : handleClick}
  ><>
      {isLoading && <span className="loading loading-spinner loading-xs"></span>}
      {text}
    </>
  </button>
  );
};

export default ButtonSignin;
