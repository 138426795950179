import Link from "next/link";
import Image from "next/image";
import config from "@/config";
import logo from "@/app/icon.png";
import slack from "@/app/logos/slack.png";
import twitter from "@/app/logos/twitter.png";

// Add the Footer to the bottom of your landing page and more.
// The support link is connected to the config.js file. If there's no config.mailgun.supportEmail, the link won't be displayed.

const Footer = () => {
  return (
    <footer className="bg-base-200 border-t border-base-content/10">
      <div className="max-w-7xl mx-auto px-8 py-24">
        <div className=" flex lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
          <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
            <Link
              href="/#"
              aria-current="page"
              className="flex gap-2 justify-center md:justify-start items-center"
            >
              <Image
                src={logo}
                alt={`${config.appName} logo`}
                priority={true}
                className="w-6 h-6"
                width={24}
                height={24}
              />
              <strong className="font-extrabold tracking-tight text-base md:text-lg">
                {config.appName}
              </strong>
            </Link>

            <p className="mt-3 text-sm text-base-content/80">
              {config.appDescription}
            </p>
            <p className="mt-3 text-base-content/60 text-sm">
              <a href="https://status.muyan.io/" target="_blank">
                System status <Image src="https://uptime.betterstack.com/status-badges/v1/monitor/usfu.svg" alt="All System status" width={88} height={20} className="inline-block ml-3" />
              </a>
            </p>
            <p className="mt-3 text-base-content/60 text-sm">
              <a href="https://theresanaiforthat.com/ai/supa-doc/?ref=featured&v=723350" target="_blank">
                <Image width="250" height="51" src="https://media.theresanaiforthat.com/featured3.png" alt="Feature SupaDoc.ai" />
              </a>
            </p>
            <p className="mt-3 text-sm text-base-content/60">
              Copyright © {new Date().getFullYear()} - All rights reserved
            </p>
          </div>
          <div className="flex-grow flex flex-wrap justify-center -mb-10 md:mt-0 mt-10 text-center">
            <div className="lg:w-1/3 md:w-1/2 w-full px-4">
              <div className="footer-title font-semibold text-base-content tracking-widest text-sm md:text-left mb-3">
                LINKS
              </div>

              <div className="flex flex-col justify-center items-center md:items-start gap-2 mb-10 text-sm">
                {config.mailgun.supportEmail && (
                  <a
                    href={`mailto:${config.mailgun.supportEmail}`}
                    target="_blank"
                    className="link link-hover"
                    aria-label="Contact Support"
                  >
                    Email Support
                  </a>
                )}
                <Link href="https://muyan.io/#pricing" className="link link-hover">
                  Pricing
                </Link>
                <Link href="https://muyan-tech.canny.io/changelog" className="link link-hover" target="_blank">
                  Changelog
                </Link>
                <Link href="https://muyan-tech.canny.io/feature-requests" className="link link-hover" target="_blank">
                  Feature Requests
                </Link>
                <Link href="https://muyan-tech.canny.io" className="link link-hover" target="_blank">
                  Public Roadmap
                </Link>
              </div>
            </div>

            <div className="lg:w-1/3 md:w-1/2 w-full px-4">
              <div className="footer-title font-semibold text-base-content tracking-widest text-sm md:text-left mb-3">
                LEGAL
              </div>

              <div className="flex flex-col justify-center items-center md:items-start gap-2 mb-10 text-sm">
                <Link href="/tos" className="link link-hover">
                  Terms of services
                </Link>
                <Link href="/privacy-policy" className="link link-hover">
                  Privacy policy
                </Link>
              </div>
            </div>

            <div className="lg:w-1/3 md:w-1/2 w-full px-4">
              <div className="footer-title font-semibold text-base-content tracking-widest text-sm md:text-left mb-3">
                Support
              </div>
              <div className="flex flex-col md:flex-row items-center md:items-start gap-2 mb-10 text-sm">
                <Link
                  href={config.support.slackUrl}
                  className="link link-hover"
                  target="_blank"
                  title="We support our customers on Slack. Join us!"
                >
                  <Image src={slack} alt="Slack" height="30" />
                </Link>
                <Link
                  href={config.support.twitterUrl}
                  className="link link-hover"
                  target="_blank"
                  title="We support our customers on Twitter. Follow us!"
                >
                  <Image src={twitter} alt="Twitter" height="30" />
                </Link>
              </div>
            </div>

          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
