"use client";
import React, { useState, useEffect, useRef } from 'react';
import { createClientComponentClient } from "@supabase/auth-helpers-nextjs";
import config from '@/config';

interface FAQItemProps {
  question: string;
  answer: string;
}

const FAQ = () => {

  const [faqList, setFaqList] = useState([]);
  const supabase = createClientComponentClient();

  useEffect(() => {
    const fetchFAQs = async () => {
      const { data, error } = await supabase
        .from('faq_entries')
        .select('*')
        .eq("tenant", config.tenant);

      if (error) {
        console.error('Error fetching FAQs:', error);
        return;
      }

      setFaqList(data);
    };

    fetchFAQs();
  }, [supabase]);

  const FaqItem = (props: { item: FAQItemProps }) => {
    const { item } = props;
    const accordion = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    return (
      <li>
        <button
          className="relative flex gap-2 items-center w-full py-5 text-base font-semibold text-left border-t md:text-lg border-base-content/10"
          onClick={(e) => {
            e.preventDefault();
            setIsOpen(!isOpen);
          }}
          aria-expanded={isOpen}
        >
          <span
            className={`flex-1 text-base-content ${isOpen ? "text-primary" : ""}`}
          >
            {item?.question}
          </span>
          <svg
            className={`flex-shrink-0 w-4 h-4 ml-auto fill-current`}
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              y="7"
              width="16"
              height="2"
              rx="1"
              className={`transform origin-center transition duration-200 ease-out ${isOpen && "rotate-180"
                }`}
            />
            <rect
              y="7"
              width="16"
              height="2"
              rx="1"
              className={`transform origin-center rotate-90 transition duration-200 ease-out ${isOpen && "rotate-180 hidden"
                }`}
            />
          </svg>
        </button>

        <div
          ref={accordion}
          className={`transition-all duration-300 ease-in-out opacity-80 overflow-hidden`}
          style={
            isOpen
              ? { maxHeight: accordion?.current?.scrollHeight, opacity: 1 }
              : { maxHeight: 0, opacity: 0 }
          }
        >
          <div className="pb-5 leading-relaxed">{item?.answer}</div>
        </div>
      </li>
    );
  };


  return (
    <section className="bg-base-200 py-24" id="faq">
      <div className="px-8 max-w-7xl mx-auto flex flex-col gap-12">
        <div className="flex flex-col text-center">
          <p className="sm:text-4xl text-3xl font-extrabold text-base-content">
            Frequently Asked Questions
          </p>
        </div>
        <ul>
          {faqList.map((item, i) => (
            <FaqItem key={i} item={item} />
          ))}
        </ul>
      </div>
    </section>
  );
};

export default FAQ;
