const FutureFeaturesSection = () => {
  return (
    <section className="bg-base-100 px-4 py-16 mt-12 max-w-7xl mx-auto" id="innovations">
      <div className="text-center mb-12">
        <h2 className="text-2xl lg:text-3xl font-bold text-white">
          Upcoming Innovations
        </h2>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">      
        <div className="bg-gray-800 shadow-lg rounded-lg p-6">
          <h3 className="font-semibold text-lg text-gray-300">AI Translation</h3>
          <p className="text-sm text-gray-400">Effortless document translation for global reach.</p>
        </div>       
        <div className="bg-gray-800 shadow-lg rounded-lg p-6">
          <h3 className="font-semibold text-lg text-gray-300">UAT Test Case Generation</h3>
          <p className="text-sm text-gray-400">User Acceptance Testing Case Auto-Generator.</p>
        </div>
        <div className="bg-gray-800 shadow-lg rounded-lg p-6">
          <h3 className="font-semibold text-lg text-gray-300">Marketing Content</h3>
          <p className="text-sm text-gray-400">Automated, creative marketing copywriting.</p>
        </div>
        <div className="bg-gray-800 shadow-lg rounded-lg p-6">
          <h3 className="font-semibold text-lg text-gray-300">Auto PPT</h3>
          <p className="text-sm text-gray-400">From ideas to impactful PowerPoint presentations.</p>
        </div>
        <div className="bg-gray-800 shadow-lg rounded-lg p-6">
          <h3 className="font-semibold text-lg text-gray-300">Event Planning</h3>
          <p className="text-sm text-gray-400">Simplify event planning with AI assistance.</p>
        </div>
        <div className="bg-gray-800 shadow-lg rounded-lg p-6">
          <h3 className="font-semibold text-lg text-gray-300">Video Scripts</h3>
          <p className="text-sm text-gray-400">Narratives to scripts for compelling videos.</p>
        </div>
      </div>
    </section>
  );
};

export default FutureFeaturesSection;
