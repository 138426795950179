"use client";

import { DocumentArrowDownIcon, DocumentIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { LogSnag } from '@logsnag/next/server';
import config from '@/config';

interface ListItemProps {
    prompt: string;
    documentType: string;
    downloadUrl: string;
}

const ListItem: React.FC<ListItemProps> = ({ prompt, documentType, downloadUrl }) => {
    const truncatedPrompt = prompt.length > 250 ? prompt.substring(0, 250) + '...' : prompt;
    const logsnag = new LogSnag(config.logSnag);
    const downloadUrlCN = downloadUrl.replace('.docx', ' CN.docx');
    const downloadUrlEN = downloadUrl.replace('.docx', ' EN.docx');

    const handleDownload = (url: string, lang: string) => {
        logsnag.track({
            channel: "sample_download",
            event: `${documentType} - ${lang}`,
            icon: "⬇️",
            tags: {
                document_type: documentType,
                language: lang,
                referer: sessionStorage?.getItem('referer'),
            }
        });
        window.open(url, "_blank");
    };

    return (
        <div className="bg-gray-800 rounded-lg shadow-md p-4 m-4 sm:m-2 sm:p-4 lg:p-8 lg:m-4 border border-gray-700">
            <div className="flex justify-between items-center mb-2">
                <h3 className="text-xl font-semibold text-white">
                    <DocumentIcon className="w-5 h-5 inline mr-1 pb-1" /> {documentType}
                </h3>
                <div className="flex space-x-2">
                    <a href={downloadUrlCN}
                        target="_blank"
                        onClick={(e) => {
                            e.preventDefault();
                            handleDownload(downloadUrlCN, 'CN');
                        }}
                        download
                        className="inline-flex items-center text-sm font-medium hover:text-blue-400">
                        <DocumentArrowDownIcon className="w-5 h-5 mr-1" />
                        中文
                    </a>
                    <a href={downloadUrlEN}
                        target="_blank"
                        onClick={(e) => {
                            e.preventDefault();
                            handleDownload(downloadUrlEN, 'EN');
                        }}
                        download
                        className="inline-flex items-center text-sm font-medium hover:text-blue-400">
                        <DocumentArrowDownIcon className="w-5 h-5 mr-1" />
                        EN
                    </a>
                </div>
            </div>
            <div className="text-sm text-gray-400 mt-2">
                <span className="inline-block bg-primary mr-2 mb-1 text-white px-2 py-1 text-xs font-semibold rounded">
                    Description
                </span>
                <span className='leading-loose'>{truncatedPrompt}</span>
            </div>
        </div>
    );
};
interface ListProps {
    items: {
        prompt: string;
        documentType: string;
        downloadUrl: string;
    }[];
}

const List: React.FC<ListProps> = ({ items }) => {
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
            {items.map((item, index) => (
                <ListItem key={index} prompt={item.prompt} documentType={item.documentType} downloadUrl={item.downloadUrl} />
            ))}
        </div>
    );
};

const items = [   
    {
        prompt: '要为系统工程师做一个SAAS工具平台。这个平台需要系统工程师输入一段描述，然后平台使用AI来分析这段描述，然后按照分析结果来生成一个系统功能细化的文档。现在需要推广这个SAAS平台，需要一个营销计划。',
        documentType: 'Marketing Plan',
        downloadUrl: '/samples/Marketing Plan.docx' 
    },
    {
        prompt: '要为系统工程师做一个SAAS工具平台。这个平台需要系统工程师输入一段描述，然后平台使用AI来分析这段描述，然后按照分析结果来生成一个系统功能细化的文档。现在需要推广这个SAAS平台，需要一个商业计划书。',
        documentType: 'Business Plan',
        downloadUrl: '/samples/Business Plan.docx' 
    },
    {
        prompt: '要为系统工程师做一个SAAS工具平台。这个平台需要系统工程师输入一段描述，然后平台使用AI来分析这段描述，然后按照分析结果来生成一个系统功能细化的文档。',
        documentType: 'System Function Design',
        downloadUrl: '/samples/Function Design.docx' 
    },
    {
        prompt: 'This is a SAAS product for the System Engineer. The System Engineer will input some description. The product will use the latest AI technology to analysis the description. Then the product will generate one Requirement Document according to AI analysis data. Now, there is a new function that user can list all the generated documents. Users click the “History” button. The product will list all the generated files include the file name, generated time etc. Generate the Test Case for the new function.',
        documentType: 'Test Case Design(English Only)',
        downloadUrl: '/samples/Test Cases.docx' 
    },
    {
        prompt: 'A FaaS platform, able to run JVM code and edit code online.',
        documentType: 'Software System Design',
        downloadUrl: '/samples/Software System.docx' 
    },
];

const DocumentSampleList: React.FC = () => {
    return (
        <section className="bg-base-200 py-24" id="samples">
            <h2 className="text-3xl font-bold lg:text-5xl tracking-tight mb-8 m-auto text-center">
                Description and Document Samples
            </h2> {/* Section Title */}
            <div className="px-8 max-w-7xl mx-auto flex flex-col md:flex-row gap-12">
                <List items={items} />
            </div>
        </section>
    );
}

export default DocumentSampleList;